.section-info {
    margin-top: 20%;
}

.title {
    font-size: 24px;
    margin-bottom: 20px
}

.description {
    font-size: 14px;
    text-align: 'justify';
}

.header {
    background-image: "url(./assets/feature/grid-bg-png.png)";
    background-position: "bottom";
    background-size: "100% auto";
    background-repeat: "no-repeat"
}

.zone {
    height: 100%;
    width: auto;
    margin: 0;
    padding-left: 0px;
    background-image: url('/assets/feature/grid-bg-png.png');
    background-repeat: no-repeat;
    background-position: left;
}

.header-title {
    font-size: 30px;
    font-weight: bold;
    padding-top: 10%;
    padding-left: 10%;
}

.get-started {
    margin-left: 10%;
}

.image-block {
    padding: 0px;
}

.image-left {
    width: 50%;
    margin-left: 50px;
}

.image-right {
    width: 50%;
    margin-right: -15px;
}

.big-logo-xs {
    width: 80%;
    margin-top: 25px;
    margin-bottom: -100px;
    margin-left: -100px;
}

@media only screen and (max-width: 575.98px) {
    .request-demo {
        background-color: #D7E7EE;
        padding-top: 50px;
        padding-bottom: 30px;
        margin-top: 45px;
    }

    .position-xs {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .request-body {
        width: 80%;
        padding-left: 20px;
    }

    .request-image {
        width: 60%;
        margin-bottom: 30px;
    }

    .request-title {
        font-size: 17px;
    }
}

@media only screen and (min-width: 576px) {
    .request-demo {
        height: 40%;
        background-color: #D7E7EE;
        padding-bottom: 5%;
        margin-bottom: 75px;
    }

    .request-image {
        position: relative;
        bottom: -30%;
    }

    .request-body {
        position: relative;
        bottom: calc(45% - 95%);
    }

    .request-title {
        font-size: medium;
    }
}

@media only screen and (min-width: 768px) {
    .email-logo {
        margin-left: -20%;
    }

    .section-info {
        margin-top: 40%;
    }

    .header-title {
        font-size: 32px;
        font-weight: bold;
        padding-top: 10%;
        margin-left: -50%;
    }

    .get-started {
        margin-left: -40%;
    }

    .big-logo-md {
        width: 110%;
        height: 100%;
        background-image: url('/assets/feature/big-logo-png.png');
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        margin-left: -30%;
    }

    .image-left {
        margin-top: 15%;
        margin-left: -45%;
        margin-right: 50%;
    }
}
