/*@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap');
@import url('/assets/web');
*/

@font-face {
  font-family: Source Sans Pro;
  src: url(/assets/fonts/SourceSansPro-Regular.woff2) format("woff2"),
    url(/assets/fonts/SourceSansPro-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Manrope3;
  src: url(/assets/fonts/Manrope-Regular.woff2) format("woff2"),
    url(/assets/fonts/Manrope-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

html,
body {
  font-family: "Source Sans Pro" !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Manrope3";
  font: Bold 24px/36px Manrope3;
}

.App {
  /*text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dropdown-menu.show {
  left: auto;
  right: 0;
  top: 50px;
  border-radius: 0;
}

.navbar-nav .nav-link {
  padding-right: 0px !important;
  padding-left: 15px !important;
}

.centerplay {
  position: absolute;
  top: calc(50% - 26px);
  width: 52px;
  margin: auto calc(50% - 26px);
}

.mainNav .dropdown-menu {
  min-width: auto;
  padding: 0;
}

.mainNav .dropdown-item {
  color: #808080;
  padding: 15px 20px;
}

.mainNav .dropdown-item img {
  margin-top: -3px;
}

.mainNav .dropdown-menu a {
  width: 100%;
  margin: 0;
}

.mainNav .dropdown-item.active,
.mainNav .dropdown-item:active {
  background-color: #d9d9d9;
}

.mainNav .navbar-nav .dropdown-menu {
  position: absolute;
}

.onTopVideo {
  transition: top 1s;
}

.videoBlue {
  background-color: #0089e1;
  color: #fff;
  padding-top: 98px;
  padding-bottom: 240px;
  text-align: center;
}

.navbar-nav a {
  font-size: 12px;
}

.btn {
  text-transform: uppercase;
  font-weight: bold !important;
  font-size: 13px !important;
}

.btn-link {
  text-decoration: none !important;
}

.btn-primary,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0089e1 !important;
  border-color: #0089e1 !important;
  font-size: 13px;
  padding: 13px 20px;
  min-width: 128px;
}

.btn.btn-lg {
  /*font-size: 1.25rem !important;*/
  font-weight: bold;
  padding: 10px 17px;
}

.days .btn-primary,
.days .show > .btn-primary.dropdown-toggle {
  min-width: auto !important;
}

.mainNav .dropdown-toggle::after {
  content: none !important;
}

.videoParagraph {
  font-size: 14px;
  max-width: 565px;
  margin: auto;
}

.f1Container {
  max-width: 700px;
  margin: auto;
}

.f2Container {
  max-width: 614px;
  margin: auto;
}

.cta2container,
.Ctaone .container > div {
  max-width: 614px;
  margin: auto;
}

.cta2container p,
.Ctaone p {
  font-family: "Manrope3" !important;
}

.footerlinks {
  flex-direction: row !important;
}

.footerlinks a.nav-link:last-child:after {
  content: none;
}

.footerlinks a.nav-link {
  margin-left: 20px;
}

.footerlinks a.nav-link:first-child {
  margin-left: 0;
}

.footerlinks a.nav-link:after {
  content: " ";
  background: #808080;
  width: 5px !important;
  height: 5px !important;
  display: inline;
  border-radius: 50%;
  position: absolute;
  margin-top: 7px;
  margin-left: 15px;
}

.onTopVideo {
  max-width: 722px;
  margin: auto;
}

/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
  .onTopVideo {
    position: relative;
    top: -120px;
    margin: auto 15px;
  }

  .videoBlue {
    padding-bottom: 190px;
  }

  .mainCarousel {
    height: 100vh;
  }

  .videoParagraph {
    font-size: 14px;
    max-width: 565px;
    margin: auto;
    padding: 0 25px;
  }

  .cta2container {
    margin: auto 23px;
  }

  .Ctaone .container > div {
    margin: auto 38px;
  }

  .cta2container p,
  .Ctaone p {
    font-size: 20px !important;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-size: 50% !important;
  }

  .dropdown-toggle > span > div {
    width: 60px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.dropdown-toggle > span::after {
  margin-top: 0px;
  display: inline-block;
  margin-left: 7px;
  vertical-align: 0.255em;
  content: "";
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
  color: #808080;
}

.dropdown-toggle span > span::after {
  content: none;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 767px) {
  .mainCarousel {
    height: 660px;
    text-align: center;
    padding: 50px 15px;
  }

  .mainCarousel .carouselItemImage {
    margin-top: 30px;
  }

  .mainCarousel .carouselText {
    font-size: 14px;
  }

  .mainCarousel .carouselTitle {
    font-size: 20px;
  }

  .mainCarousel .carouselItemImage img {
    height: 300px !important;
  }

  .footerlinks {
    display: none !important;
  }

  #feature1 {
    text-align: center;
    margin: auto;
  }

  .Ctaone h3 {
    max-width: 216px;
    margin: auto;
  }

  .Ctaone {
    padding: 44px 0 54px 0;
  }

  #feature2 {
    padding-top: 96px !important;
    padding-bottom: 46px !important;
  }
}

@media only screen and (max-width: 768px) {
  .dashSidebar {
    width: 0 !important;
  }
}

@media only screen and (min-width: 600px) {
  .onTopVideo {
    position: relative;
    top: -192px;
  }
}

/* Medium devices (landscape tablets, 767px and up)*/
@media only screen and (max-width: 768px) {
  /*.mainCarousel {
      background-image: url(/assets/landing/Tablet/SVG/landing-page-tablet-background.svg) !important;
      background-position: bottom;
      background-size: 100% auto;
      background-repeat: no-repeat;
      padding-top: 50px;
      height: 723px !important;
    }*/
  .Hello,
  .Seturl,
  .Calendar,
  .Video {
    height: 100% !important;
  }
}

@media only screen and (min-width: 768px) {
  .mainCarousel {
    height: calc(100vh - 128px) !important;
    padding: 50px 15px;
    align-items: center;
    display: flex;
  }

  .mainCarousel .carouselItemImage {
    justify-content: center;
    text-align: right;
  }

  .f1Container {
    max-width: 800px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.carouselTitle {
  color: #373737;
  font-size: 30px;
  margin-bottom: 15px;
}

.carouselText {
  color: #808080;
  line-height: 30px;
  margin-bottom: 30px;
}

/*
.carouselItemText{
  padding: 10vh 0 10vh 5vw;
  width:50%
}
.carouselItemImage{
  text-align: center;
}
*/
.Topcarousel .carousel-indicators li {
  width: 18px;
  height: 18px;
  background-color: #d3d9e0;
  border-radius: 50%;
}

.Topcarousel .carousel-indicators {
  align-items: center;
}

.Topcarousel .carousel-indicators li.active {
  background-color: #2acc00;
  border: solid 4px white;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.carousel-control-prev-icon {
  background-image: url("/assets/left-chevron.svg") !important;
}

.carousel-control-next-icon {
  background-image: url("/assets/right-chevron.svg") !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 20px !important;
  height: 50px !important;
}

.Hello,
.Seturl,
.Calendar,
.Video,
.Schedule {
  min-height: calc(100vh - 148px);
}

.Schedule {
  /*min-height: calc(100vh - 277px);*/
  /*margin-bottom: 46px;
    margin-top: 146px;*/
}

.Schedule .row,
.Schedulepage .row,
.Calendar .row,
.Video .row,
.Hello .row,
.Schedule .row,
.Seturl .row {
  /*height: 100%;
    align-items: center;*/
  padding: 10vh 0 15px;
}

.Hello .triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 0 0 16px;
  border-color: transparent transparent transparent rgba(0, 137, 233, 0.05);
}

.days .btn {
  padding: 15px 17px;
  margin-right: 10px;
}

span.css-1okebmr-indicatorSeparator {
  display: none;
}

.form-control:focus,
.form-control-file:focus {
  box-shadow: 0 0 0 0rem rgba(40, 167, 69, 0.25) !important;
  border: solid 2px !important;
  border-color: rgb(0, 137, 225) !important;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.Schedule table,
.Schedule tr,
.Schedule thead,
.Schedule tbody,
.Schedule th,
.Schedule td {
  border: solid 0px #fff !important;
  border-bottom: solid 3px #fff !important;
}

.Schedule thead {
  background: #e8e8e8 0% 0% no-repeat padding-box !important;
  color: #8b8b8b !important;
}

.Schedule tr {
  background-color: #f8f8f8 !important;
  border-radius: 3px !important;
}

.Schedule td {
  vertical-align: middle !important;
  padding: 0;
}

.Schedule tr td:nth-child(3),
.Schedule tr th:nth-child(2),
.Schedule tr th:nth-child(4) {
  padding-left: 15px;
}

.Schedule tr td:nth-child(1),
.Schedule tr th:nth-child(1) {
  padding-left: 15px !important;
}

.dashShell {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 148px);
}

.dashSidebar {
  width: 256px;
  display: block;
  min-height: calc(100vh - 148px);
  transition: width 1ms;
}

.dashSidebar a {
  color: #808080;
  font-size: 12px;
}

.dashContent {
  width: 100%;
  display: block;
}

.mainNav,
.navbar-dark {
  text-transform: uppercase !important;
}

.mainNav .btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

a.dropdown-toggle.active + .collapse {
  display: block;
}

/*************************************/
/*!
 * Start Bootstrap - Simple Sidebar HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

/* Toggle Styles */

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #0089e1;
  background: #fafbfe;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0 !important;
  }

  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  #wrapper {
    /*padding-left: 250px;*/
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    /*width: 250px;*/
    background-color: white;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 250px !important;
  }

  #page-content-wrapper {
    /*padding: 20px;*/
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper,
  #page-content-wrapper {
    /*padding: 20px;*/
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    /*position: relative;
        margin-right: 0;*/
  }

  .AccountManagers .table {
    width: max-content;
  }
}

#sidebar-wrapper a div img {
  margin-right: 15px;
}

#sidebar-wrapper a div {
  align-items: center;
}

#sidebar-wrapper .collapse a.active div {
  border-left: solid 5px #0089e1;
  font-weight: bold;
  background-color: #fafbfe;
  padding-left: 25px;
}

#sidebar-wrapper div a div {
  padding-left: 30px;
  box-sizing: content-box;
}

#sidebar-wrapper a.active div {
  color: #0089e1;
  font-weight: bold;
}

.Schedule .btn-outline-secondary:hover {
  color: #0084e8;
  background-color: white;
  border-color: #0084e8;
}

.Schedule .btn-outline-secondary:active {
  color: white;
  background-color: #0084e8 !important;
  border-color: #0084e8 !important;
}

.Account .nav-tabs {
  border-bottom: solid 0px;
}

.Account .nav-tabs .nav-link {
  border: 0px solid transparent;
  color: inherit;
}

.Account .nav-tabs .nav-link.active {
  border: 0px solid transparent;
  color: #0084e8;
  font-weight: bold;
  background-color: inherit;
  border-bottom: solid 3px #0084e8;
}

.superTable,
.superTable * {
  border: none !important;
  font-size: 12px;
}

.superTable {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.superTable thead {
  background-color: #0082f51a;
}

.superTable td,
.superTable th {
  padding: 10px !important;
}

.superTable td {
  border-top: solid 1px #eaeaea !important;
  border-bottom: solid 1px #eaeaea !important;
}

.superTable tr td:first-child {
  border-top-left-radius: 5px !important;
  border-left: solid 1px #eaeaea !important;
}

.superTable tr td:last-child {
  border-top-right-radius: 5px !important;
  border-right: solid 1px #eaeaea !important;
}

.superTable tr td:first-child {
  border-bottom-left-radius: 5px !important;
}

.superTable tr td:last-child {
  border-bottom-right-radius: 5px !important;
  max-width: 96px;
}

.dropdown-menu {
  max-width: 256px !important;
  max-height: 256px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.ServiceList table a {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
}

.tabsNavService {
  margin-top: 20px;
  font-size: 12px;
}

.tabsNavService .nav-link.active {
  color: #0087e4;
  font-weight: bold;
}

.tabsNavService .nav-link {
  padding-left: 0;
  padding-bottom: 0;
  color: #bbbbbb;
}

#loadingContainer {
  width: 0;
  height: 0;
}

#loadingContainer.showLoading {
  display: inline-block !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.Loading {
  background-color: rgba(255, 255, 255, 0.85);
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.specialInputGroup .dropdown-toggle {
  border: 1px solid #ced4da;
}
.specialInputGroup .form-control {
  border-left: none;
  border-right: none;
}
.specialInputGroup .input-group-text {
  background-color: transparent;
  border-right: none;
}
.serviceFormCost .dropdown-toggle {
  border: 1px solid #ced4da;
  background-color: #eaeaea;
  font-weight: bold;
}
.serviceFormFile .form-control-file {
  border: 1px solid #ced4da;
  padding: 5px;
  border-radius: 5px;
}
.ServiceForm .btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
