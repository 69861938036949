.rdemo-header {
    background-color: #0089E1;
    padding-bottom: 4em;
}

.rdemo-header-title {
    width: 100%;
    font-size: 28pt;
    text-align: center;
    color: #FFF;
}

.rdemo-body-move {
    margin-top: -70px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: white;
    border-radius: 4px;
    -webkit-box-shadow: 5px 5px 20px rgba(200,200,200,0.5);
    -moz-box-shadow: 5px 5px 20px rgba(200,200,200,0.5);
    box-shadow: 5px 5px 20px rgba(200,200,200,0.5);
}

.rdemo-body-details {
    padding-top: 50px;
    padding-bottom: 20px;
}

.rdemo-body-title {
    font-size: 20pt;
    font-weight: bold;
    margin-bottom: 45px;
}

.rdemo-body-list {
    font-size: 13pt;
    color: #808080;
}

.rdemo-body-list div {
    padding: 2px;
}

.fa-color {
    color: #0089E1;
    margin-right: 15px;
}

.display {
    display: inline-block;
}

.hide {
    display: none;
}

.phone-style input, .phone-style input:focus, .phone-style input:active, .phone-style input:hover {
    border: 1px solid transparent !important;
}
